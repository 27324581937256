.start-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  overflow: hidden;
  position: relative;
}

.video-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
          to top,
          rgba(138, 106, 208, 0.491),
          rgba(138, 106, 208, 0.491),
          rgba(138, 106, 208, 0.491),
          transparent
  );
  z-index: 1;
}

.video-background video,
.video-background img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.headers {
  text-align: center;
  z-index: 1;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1500ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1500ms ease-in-out;
}
