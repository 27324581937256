@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

*,
*::after,
*::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

.item {
    flex: 1 0 33.33%; /* 1 = grow factor, 0 = shrink factor, 33.33% = basis width */
    margin: 10px;
    min-height: 200px; /* Ensure items have a consistent minimum height */
}

@media (max-width: 768px) {
    .item {
        flex: 1 0 100%; /* Single column for mobile */
        margin: 10px 0; /* Adjust spacing for mobile */
    }
}

html {
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
}

h1 {
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 2rem;
}

h2 {
    font-size: 1.5rem;
    font-weight: 500;
}

h5,
h4,
h3,
h2,
h1 {
    color: #534382;
    font-family: "Lexend", sans-serif;
    font-weight: 300;
}

p {
    font-size: 1.125rem;
    font-weight: 400;
}

p.bold {
    font-weight: 500;
}

p.light {
    color: #9CA3AF;
    font-weight: 300;
}

p.description {
    font-size: 0.875rem;
    font-weight: 300;
}

a {
    font-size: 1.125rem;
    font-weight: 300;
    color: #A78BFA;
    text-decoration: none;
    transition: ease-in-out 250ms;
    cursor: pointer;
}

a:hover {
    color: #8B5CF6;
}

.flex {
    display: flex;
}
.cols {
    flex-direction: column;
}
.rows {
    flex-direction: row;
}
.jcc {
    justify-content: center;
    align-items: center;
}

.jcm {
    margin: auto 0;
}

.jcsb {
    justify-content: space-between;
}

.p-16 {
    padding: 1rem;
}
.m-16 {
    margin: 1rem;
}

.m-32 {
    margin: 2rem;
}

.m-64 {
    margin: 3rem;
}

.popup {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    opacity: 1; /* Ensure the popup is not transparent */
  }

  .popup-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.659);
      display: flex;
      justify-content: center;
      align-items: center;
    }
.is-done {
    opacity: 0.4;
}
.status{
    opacity: 0.9 !important;
}
/*driving license category */
.flex {
    display: flex;
}
.cols {
    flex-direction: column;
}

.rows {
    flex-direction: row;
}

.center {
    margin: 0 auto;
    text-align: center;
    justify-content: center;

}

spinner

.rotate360 {
    animation: spin 3s linear 2;

}
.lrotate360 {
    animation: spin 1s linear 2;

}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.jcc {
    justify-content: center;
    align-items: center;
}

.jcm {
    margin: auto 0;
}
.jcsb {
    justify-content: space-between;
}

/* More specific margin and padding classes */
.pr-16 { padding-right: 1rem; }
.p-16 { padding: 1rem; }
.p-24 { padding: 1.5rem; }
.p-32 { padding: 2rem; }
.p-48 { padding: 2.5rem; }
.pr-24 { padding-right: 1.4rem; }
.pr-32 { padding-right: 2rem; }
.pl-64 { padding-left: 3rem; }
.pr-8 { padding-right: 0.5rem; }
.pl-8 { padding-left: 0.5rem; }
.pr-64 { padding-right: 3rem; }
.pl-32 { padding-left: 2rem; }
.pl-16 { padding-left: 1rem; }
.pt-32 { padding-top: 2rem; }
.pt-64 { padding-top: 4rem; }
.pt-16 { padding-top: 1rem; }
.pb-16 { padding-bottom: 1rem; }
.pb-32 { padding-bottom: 2rem; }
.pb-8 { padding-bottom: 0.5rem; }
.pt-8 { padding-top: 0.5rem; }
.mb-16 { margin-bottom: 1rem; }
.mb-8 { margin-bottom: 0.5rem; }
.mb-32 { margin-bottom: 2rem; }
.mb-64 { margin-bottom: 4rem; }
.mt-64 { margin-top: 4rem; }
.mt-128 { margin-top: 8rem; }
.mt-32 { margin-top: 2rem; }
.mt-16 { margin-top: 1rem; }
.mt-8 { margin-top: 0.5rem; }
.ml-32 { margin-left: 2rem; }
.mr-32 { margin-right: 2rem; }
.ml-64 { margin-left: 3rem; }
.mr-64 { margin-right: 3rem; }

.bg1 {
    background-color: #ebe9e9;
    border-radius: 5px;
}
.bg2 {
    background-color: #7e7e7e;
}

.textarea,
textarea,
input,
.input {
    border: none;
    color: #3F3F46;
    font-weight: 400;
    width: 100%;
}

label{
    font-family: "Inter", sans-serif;
}

input,
.input {
    font-family: "Inter", sans-serif;
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem 1.25rem;
    border: 1px solid #E7E5E4;
    font-size: 1.125rem;
    transition: ease-in-out 250ms;
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) center;
    margin-bottom: 2rem;
}

textarea,
.textarea{
    font-family: "Inter", sans-serif;
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem 1.25rem;
    border: 1px solid #E7E5E4;
    font-size: 1.125rem;
    transition: ease-in-out 250ms;
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) center;
}
select,
.select {
    font-family: "Inter", sans-serif;
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem 1.25rem;
    border: 1px solid #E7E5E4;
    font-size: 1.125rem;
    transition: ease-in-out 250ms;
    background-repeat: no-repeat;
    background-position: calc(100% - 0.3rem) center;
    margin-bottom: 1.4rem;
    margin-top: 0.5rem;
    width: 100%;
}

textarea:hover,
textarea:focus,
input:hover,
input:focus,
.input:hover,
.input:focus,
select:hover,
select:focus {
    outline: none;
    border: 1px solid #A78BFA;
    color: #3F3F46;
}






/* Styling for the checkbox */
.checkbox {
    width: 1.7rem; /* Ensure checkbox does not stretch */
    height: 1.7rem;
    margin: 0; /* Remove default margin */
}














/*button,*/
.button {
    font-family: "Inter", sans-serif;
    border: none;
    background-color: #A78BFA;
    cursor: pointer;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 10px;
    transition: ease-in-out 250ms;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.71);
    align-items: center;
    justify-content: center;
}

.buttonstp {
    font-family: "Inter", sans-serif;
    background-color: #A78BFA;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 10px;
    transition: ease-in-out 250ms;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    width: 100%;
        box-shadow: 0 2px 5px 0 rgb(213, 1, 255);
  }
  .buttonenter {
    font-family: "Inter", sans-serif;
    background-color: #a78bfabc;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.425rem;
    font-weight: 400;
    border-radius: 10px;
    transition: ease-in-out 250ms;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }

.buttonRED {
    font-family: "Inter", sans-serif;
    border-color: #682100;
    background-color: #682100;
    cursor: pointer;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 10px;
    transition: ease-in-out 250ms;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.71);
}

.ghostbutton {
    font-family: "Inter", sans-serif;
    border-color:  #A78BFA;
    background-color: rgba(167, 139, 250, 0);
    cursor: pointer;
    color: #54319f;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 10px;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    width: 100%;
    transition: background-color 250ms ease-in-out;
}

.ghostbutton:hover
{
    background-color: #8B5CF6;
    color: #ffffff;
    transition: ease-in-out 250ms;
    transition: background-color 250ms ease-in-out
}

.ghostbuttonP {
    font-family: "Inter", sans-serif;
    border-color: #FFD700;
    background: rgba(167, 139, 250, 0);
    cursor: pointer;
    color: #321556;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 10px;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    width: 100%;
    transition: background-color 550ms ease-in-out

}
.ghostbuttonP:hover {
    background-color: #FFD700 !important;
    transition: background-color 550ms ease-in-out
    }

.card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.31);
}

.card2 {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.31);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: 1rem;
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
    justify-content: center;
}

button:hover,
.button:hover {
    background-color: #8B5CF6;
    transition: ease-in-out 350ms;
}

p {
    font-size: 1.125rem;
    font-weight: 400;
}

p.bold {
    font-weight: 500;
}

p.light {
    font-weight: 200;
}

p.menu {
    color: #54319f;
    font-weight: 500;
    background-color: #ebebeb;
    border-radius: 5px;
    font-size: 1.275rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#menuitem:hover{
    color: #8f67ea;
    background-color: #f2f2f2;
}
p.description {
    font-size: 0.875rem;
    font-weight: 300;
}
p.error {
    color: #FB7185;
    margin-top: -1.5rem;
    padding-bottom: 2rem;
}
.error {
    color: #FB7185;
    margin-top: -1.5rem;
    padding-bottom: 2rem;
}
.done {
    background-color: #e4ffbe;
}
p.rejected {
    color: #742727;
}

button.Toastify__close-button,
.button.Toastify__close-button {
    display: none;
}
/*footer*/
body {
    position: relative;
    min-height: 100vh;
}
.base{
    margin-top: 15%;
    padding-bottom: 3rem;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.01rem;
}

.icon-container {
    display: flex;
    margin-top: 10rem;
    justify-content: space-around;
    align-items: center;
    padding: 0px;
}

.icon {
    text-align: center;
    transition: color 0.3s;
    cursor: pointer;
}

.icon:hover .icon-img,
.icon:hover .icon-text {
    color: #8B5CF6;
}

.icon-img,
.icon-text {
    transition: color 0.3s;
}

.icon-img {
    font-size: 2.1rem;
    color: #5a4d9b; /* Adjust color as needed */
}

.icon-img:hover {
    color: #8B5CF6;
}
.icon-logo-text {
    font-size: 0.9rem;
}
.icon-text {
    font-size: 1.2rem;
    color: #4e418d; /* Adjust color as needed */
}
@media only screen and (max-width: 800px) {
    .icon-text  {
      font-size: 1rem;
    }
    .icon-img {
        font-size: 1.4rem;
    }
    .icon-logo-text {
        font-size: 0.6rem;
    }
  }

/* hamburger menu */

.menu-btn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 100;
}

.menu-icon {
    font-size: 29px;
    margin-right: 0.24rem;
    margin-top: 0.24rem;
    color: #54319f;
    transition: transform 0.4s ease-in-out;
    z-index: 100;
    margin-top: -5px;
  }

  .menu-icon.close {
    transform: rotate(90deg);
  }

.menu-label {
    font-size: 14px;
    margin-top: -8px;
    right: 8px;
    color: #54319f;
}

.navtext{
    font-size: 18pt;
    font-style: normal;
}
.nav-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #f9efffb4,#ffffff, #ffffff, #ffffff,#f9efffb4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: translateX(100%);
    transition: transform 0.4s ease-in-out; /* Add transition property */
    z-index: 20;
}
.nav-menu.open {
    transform: translateX(0);
}
/* patterns */
/* global.css */
.car-pattern-container {
    display: flex;
    flex-direction: column;
    z-index: -2;
    position: fixed;

  }

  .car-row {
    display: flex;
  }

  .car-icon {
    font-size: 30px;
    margin: 90px;
    color: #efefefd9; /* Change the color as needed */
    z-index: -2;
    margin-bottom: 50px;


  }

  /* strp */

.FormGroup {
    /*margin: 0 15px 15px;*/
    border-style: none;
    background-color: rgb(239, 230, 250);
    border-radius: 10px;
}

.StripeElement {
    width: 100%;
    /*padding: 11px 35px 15px 0;*/
    background-color: #ffffff;
    padding: 8px;
    border-radius: 10px;
}
.accordion {
    padding-bottom: 1rem;
}
.accordion-m {
    padding-bottom: 1rem;
}
.accordion-title {
    display: flex;
    /*display: block;*/
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 5px;
    margin: 0 auto;

}

/* /accordion arrow */

.custom-icon {
    width: 40px;
    height: 40px;
    color: #8B5CF6;
  }




  .popup{
      width: 350px;
      height: 500px;
      background-color: #dbc7ff;
  }

.popup2{
      width: 350px;
      height: 300px;
      background-color: #f0e9fe;
  }

.popupwarn{
    width: 350px;
    height: 400px;
    background-color: #ffdcdc;
    border-radius: 8px;
}


.rocket-icon {
    color: #54319f;
    font-size: 50px; /* Adjust size as needed */
    animation: fly 2s infinite;
    display: block;
    margin: 0 auto 15px;
}

@keyframes fly {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
    100% {
        transform: translateY(0);
    }
}

.ghostbuttonC{
    font-family: "Inter", sans-serif;
    border-color: #5ebba5;
    background-color: rgba(167, 139, 250, 0);
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 10px;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    width: 100%;
    transition: background-color 250ms ease-in-out;
}

.ghostbuttonC{
    font-family: "Inter", sans-serif;
    border-color: #5ebba5;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 10px;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    width: 100%;
    transition: background-color 250ms ease-in-out;
}


.ghostbuttonC:hover
{
    transition: ease-in-out 250ms;
    transition: background-color 250ms ease-in-out

}

.ghostbuttonC:focus
{
    transition: ease-in-out 250ms;
    transition: background-color 250ms ease-in-out

}

.ghostbuttonN{
    font-family: "Inter", sans-serif;
    border-color: #b5783f;
    background-color: rgba(167, 139, 250, 0);
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 10px;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    width: 100%;
    transition: background-color 250ms ease-in-out;
}
/* cookies */
.cookie-message {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(186, 151, 224, 0.93);
    color: #fff;
    text-align: center;
    padding: 15px 0;
    z-index: 1000;
}
.cookie-message button {
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 7px  ;
    background-color: #582e59;
    border: none;
    color: #fff;
    cursor: pointer;
}

.highlighted {
    font-size: 1.2rem;
    font-weight: bold; /* Optional, if you want the text to be bold */
}

.highlighted2 {
    display: block; /* Ensures it starts on a new line */
    font-size: 1.3rem;
    font-weight: bold;
    padding-top: 1rem; /* Add padding above the text */
    margin-top: 1rem; /* Add margin above the text to ensure spacing */
    text-align: center;
}

.highlighted::before {
    content: "\A"; /* Adds a line break */
    white-space: pre; /* Ensures that the line break is rendered */
}

